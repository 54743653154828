<template>
    <div class="c-navigation-links-list">
        <ul>
            <li
                v-for="navItem in navigationLinks"
                :key="navItem.displayName"
            >
                <BaseLink
                    :to="navItem.targetUrl || navItem.slug"
                    :target="navItem.target"
                    class="c-navigation-links-list__a"
                >
                    {{ navItem.displayName }}
                </BaseLink>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'NavigationLinksList',
    components: {},
    props: {
        navigationLinks: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="scss">
    .c-navigation-links-list {
        background: $nu-white;

        &__a {
            width: 100%;
            padding: $nu-spacer-2;
            border-bottom: 1px solid $nu-gray--light;
            color: $nu-black;
        }
    }
</style>
